@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
:root {
  --maxWidth: 1200px;
  --contentWidth: min(90%, var(--maxWidth));
  --dialogWidth: min(90%, 750px);
  --borderRadius: 6px;
  --headerHeight: 6rem;
  --sizeBase: 16px;
  --xsmall: calc(var(--sizeBase) / 2);
  --small: calc(var(--sizeBase));
  --medium: calc(var(--sizeBase) * 2);
  --large: calc(var(--sizeBase) * 4);
  --xlarge: calc(var(--sizeBase) * 8);
}

:root {
  --box-shadow: 0px 9px 27px -5px hsla(209, 14%, 80%, 0.35), 0px 5px 16px -8px hsla(209, 14%, 80%, 0.6);
  --colour-background-h: 0deg;
  --colour-background-s: 0%;
  --colour-background-l: 100%;
  --colour-background-hsl: 0deg 0% 100%;
  --colour-background-light-hsl: 0deg 0% 100%;
  --colour-background-lighter-hsl: 0deg 0% 100%;
  --colour-background-lightest-hsl: 0deg 0% 100%;
  --colour-background-dark-hsl: 0deg 0% 98%;
  --colour-background-darker-hsl: 0deg 0% 95%;
  --colour-background-darkest-hsl: 0deg 0% 93%;
  --colour-font-h: 209deg;
  --colour-font-s: 34%;
  --colour-font-l: 36%;
  --colour-font-hsl: 209deg 34% 36%;
  --colour-font-light-hsl: 209deg 34% 52%;
  --colour-font-lighter-hsl: 209deg 34% 68%;
  --colour-font-lightest-hsl: 209deg 34% 84%;
  --colour-font-dark-hsl: 209deg 34% 27%;
  --colour-font-darker-hsl: 209deg 34% 18%;
  --colour-font-darkest-hsl: 209deg 34% 9%;
  --colour-andel-blue1-h: 204deg;
  --colour-andel-blue1-s: 59%;
  --colour-andel-blue1-l: 59%;
  --colour-andel-blue1-hsl: 204deg 59% 59%;
  --colour-andel-blue1-light-hsl: 204deg 59% 69%;
  --colour-andel-blue1-lighter-hsl: 204deg 59% 80%;
  --colour-andel-blue1-lightest-hsl: 204deg 59% 90%;
  --colour-andel-blue1-dark-hsl: 204deg 59% 47%;
  --colour-andel-blue1-darker-hsl: 204deg 59% 34%;
  --colour-andel-blue1-darkest-hsl: 204deg 59% 22%;
  --colour-andel-blue2-h: 198deg;
  --colour-andel-blue2-s: 88%;
  --colour-andel-blue2-l: 77%;
  --colour-andel-blue2-hsl: 198deg 88% 77%;
  --colour-andel-blue2-light-hsl: 198deg 88% 83%;
  --colour-andel-blue2-lighter-hsl: 198deg 88% 89%;
  --colour-andel-blue2-lightest-hsl: 198deg 88% 94%;
  --colour-andel-blue2-dark-hsl: 198deg 88% 65%;
  --colour-andel-blue2-darker-hsl: 198deg 88% 52%;
  --colour-andel-blue2-darkest-hsl: 198deg 88% 40%;
  --colour-andel-blue3-h: 197deg;
  --colour-andel-blue3-s: 83%;
  --colour-andel-blue3-l: 61%;
  --colour-andel-blue3-hsl: 197deg 83% 61%;
  --colour-andel-blue3-light-hsl: 197deg 83% 71%;
  --colour-andel-blue3-lighter-hsl: 197deg 83% 81%;
  --colour-andel-blue3-lightest-hsl: 197deg 83% 90%;
  --colour-andel-blue3-dark-hsl: 197deg 83% 49%;
  --colour-andel-blue3-darker-hsl: 197deg 83% 36%;
  --colour-andel-blue3-darkest-hsl: 197deg 83% 24%;
  --colour-andel-blue4-h: 200deg;
  --colour-andel-blue4-s: 89%;
  --colour-andel-blue4-l: 85%;
  --colour-andel-blue4-hsl: 200deg 89% 85%;
  --colour-andel-blue4-light-hsl: 200deg 89% 89%;
  --colour-andel-blue4-lighter-hsl: 200deg 89% 93%;
  --colour-andel-blue4-lightest-hsl: 200deg 89% 96%;
  --colour-andel-blue4-dark-hsl: 200deg 89% 73%;
  --colour-andel-blue4-darker-hsl: 200deg 89% 60%;
  --colour-andel-blue4-darkest-hsl: 200deg 89% 48%;
  --colour-andel-green-h: 68deg;
  --colour-andel-green-s: 68%;
  --colour-andel-green-l: 51%;
  --colour-andel-green-hsl: 68deg 68% 51%;
  --colour-andel-green-light-hsl: 68deg 68% 63%;
  --colour-andel-green-lighter-hsl: 68deg 68% 76%;
  --colour-andel-green-lightest-hsl: 68deg 68% 88%;
  --colour-andel-green-dark-hsl: 68deg 68% 39%;
  --colour-andel-green-darker-hsl: 68deg 68% 26%;
  --colour-andel-green-darkest-hsl: 68deg 68% 14%;
  --colour-andel-green2-h: 73deg;
  --colour-andel-green2-s: 56%;
  --colour-andel-green2-l: 50%;
  --colour-andel-green2-hsl: 73deg 56% 50%;
  --colour-andel-green2-light-hsl: 73deg 56% 63%;
  --colour-andel-green2-lighter-hsl: 73deg 56% 75%;
  --colour-andel-green2-lightest-hsl: 73deg 56% 88%;
  --colour-andel-green2-dark-hsl: 73deg 56% 38%;
  --colour-andel-green2-darker-hsl: 73deg 56% 25%;
  --colour-andel-green2-darkest-hsl: 73deg 56% 13%;
  --colour-andel-grey-h: 225deg;
  --colour-andel-grey-s: 2%;
  --colour-andel-grey-l: 47%;
  --colour-andel-grey-hsl: 225deg 2% 47%;
  --colour-andel-grey-light-hsl: 225deg 2% 61%;
  --colour-andel-grey-lighter-hsl: 225deg 2% 74%;
  --colour-andel-grey-lightest-hsl: 225deg 2% 87%;
  --colour-andel-grey-dark-hsl: 225deg 2% 36%;
  --colour-andel-grey-darker-hsl: 225deg 2% 24%;
  --colour-andel-grey-darkest-hsl: 225deg 2% 12%;
  --colour-error-h: 345deg;
  --colour-error-s: 100%;
  --colour-error-l: 39%;
  --colour-error-hsl: 345deg 100% 39%;
  --colour-error-light-hsl: 345deg 100% 54%;
  --colour-error-lighter-hsl: 345deg 100% 70%;
  --colour-error-lightest-hsl: 345deg 100% 85%;
  --colour-error-dark-hsl: 345deg 100% 29%;
  --colour-error-darker-hsl: 345deg 100% 20%;
  --colour-error-darkest-hsl: 345deg 100% 10%;
  --colour-success-h: 164deg;
  --colour-success-s: 100%;
  --colour-success-l: 29%;
  --colour-success-hsl: 164deg 100% 29%;
  --colour-success-light-hsl: 164deg 100% 47%;
  --colour-success-lighter-hsl: 164deg 100% 65%;
  --colour-success-lightest-hsl: 164deg 100% 82%;
  --colour-success-dark-hsl: 164deg 100% 22%;
  --colour-success-darker-hsl: 164deg 100% 15%;
  --colour-success-darkest-hsl: 164deg 100% 7%;
  --colour-info-h: 204deg;
  --colour-info-s: 100%;
  --colour-info-l: 40%;
  --colour-info-hsl: 204deg 100% 40%;
  --colour-info-light-hsl: 204deg 100% 55%;
  --colour-info-lighter-hsl: 204deg 100% 70%;
  --colour-info-lightest-hsl: 204deg 100% 85%;
  --colour-info-dark-hsl: 204deg 100% 30%;
  --colour-info-darker-hsl: 204deg 100% 20%;
  --colour-info-darkest-hsl: 204deg 100% 10%;
  --colour-warn-h: 44deg;
  --colour-warn-s: 100%;
  --colour-warn-l: 50%;
  --colour-warn-hsl: 44deg 100% 50%;
  --colour-warn-light-hsl: 44deg 100% 63%;
  --colour-warn-lighter-hsl: 44deg 100% 75%;
  --colour-warn-lightest-hsl: 44deg 100% 88%;
  --colour-warn-dark-hsl: 44deg 100% 38%;
  --colour-warn-darker-hsl: 44deg 100% 25%;
  --colour-warn-darkest-hsl: 44deg 100% 13%;
}

[data-theme=dark] {
  --box-shadow: 0px 26px 27px -5px hsla(245, 30%, 0%, 0.35), 0px 5px 12px -8px hsla(245, 30%, 0%, 0.8);
  --colour-background-h: 233deg;
  --colour-background-s: 28%;
  --colour-background-l: 11%;
  --colour-background-hsl: 233deg 28% 11%;
  --colour-background-dark-hsl: 233deg 28% 16%;
  --colour-background-darker-hsl: 233deg 28% 21%;
  --colour-background-darkest-hsl: 233deg 28% 26%;
  --colour-background-light-hsl: 233deg 28% 9%;
  --colour-background-lighter-hsl: 233deg 28% 6%;
  --colour-background-lightest-hsl: 233deg 28% 3%;
  --colour-font-h: 220deg;
  --colour-font-s: 13%;
  --colour-font-l: 65%;
  --colour-font-hsl: 220deg 13% 65%;
  --colour-font-dark-hsl: 220deg 13% 74%;
  --colour-font-darker-hsl: 220deg 13% 82%;
  --colour-font-darkest-hsl: 220deg 13% 91%;
  --colour-font-light-hsl: 220deg 13% 49%;
  --colour-font-lighter-hsl: 220deg 13% 32%;
  --colour-font-lightest-hsl: 220deg 13% 16%;
  --colour-andel-blue1-h: 209deg;
  --colour-andel-blue1-s: 34%;
  --colour-andel-blue1-l: 59%;
  --colour-andel-blue1-hsl: 209deg 34% 59%;
  --colour-andel-blue1-light-hsl: 209deg 34% 69%;
  --colour-andel-blue1-lighter-hsl: 209deg 34% 79%;
  --colour-andel-blue1-lightest-hsl: 209deg 34% 90%;
  --colour-andel-blue1-dark-hsl: 209deg 34% 46%;
  --colour-andel-blue1-darker-hsl: 209deg 34% 34%;
  --colour-andel-blue1-darkest-hsl: 209deg 34% 21%;
  --colour-andel-blue2-h: 196deg;
  --colour-andel-blue2-s: 66%;
  --colour-andel-blue2-l: 75%;
  --colour-andel-blue2-hsl: 196deg 66% 75%;
  --colour-andel-blue2-light-hsl: 196deg 66% 82%;
  --colour-andel-blue2-lighter-hsl: 196deg 66% 88%;
  --colour-andel-blue2-lightest-hsl: 196deg 66% 94%;
  --colour-andel-blue2-dark-hsl: 196deg 66% 63%;
  --colour-andel-blue2-darker-hsl: 196deg 66% 50%;
  --colour-andel-blue2-darkest-hsl: 196deg 66% 38%;
  --colour-andel-blue3-h: 194deg;
  --colour-andel-blue3-s: 73%;
  --colour-andel-blue3-l: 60%;
  --colour-andel-blue3-hsl: 194deg 73% 60%;
  --colour-andel-blue3-light-hsl: 194deg 73% 70%;
  --colour-andel-blue3-lighter-hsl: 194deg 73% 80%;
  --colour-andel-blue3-lightest-hsl: 194deg 73% 90%;
  --colour-andel-blue3-dark-hsl: 194deg 73% 47%;
  --colour-andel-blue3-darker-hsl: 194deg 73% 35%;
  --colour-andel-blue3-darkest-hsl: 194deg 73% 22%;
  --colour-andel-blue4-h: 200deg;
  --colour-andel-blue4-s: 90%;
  --colour-andel-blue4-l: 88%;
  --colour-andel-blue4-hsl: 200deg 90% 88%;
  --colour-andel-blue4-light-hsl: 200deg 90% 91%;
  --colour-andel-blue4-lighter-hsl: 200deg 90% 94%;
  --colour-andel-blue4-lightest-hsl: 200deg 90% 97%;
  --colour-andel-blue4-dark-hsl: 200deg 90% 75%;
  --colour-andel-blue4-darker-hsl: 200deg 90% 63%;
  --colour-andel-blue4-darkest-hsl: 200deg 90% 50%;
  --colour-andel-green-h: 68deg;
  --colour-andel-green-s: 64%;
  --colour-andel-green-l: 52%;
  --colour-andel-green-hsl: 68deg 64% 52%;
  --colour-andel-green-light-hsl: 68deg 64% 64%;
  --colour-andel-green-lighter-hsl: 68deg 64% 76%;
  --colour-andel-green-lightest-hsl: 68deg 64% 88%;
  --colour-andel-green-dark-hsl: 68deg 64% 39%;
  --colour-andel-green-darker-hsl: 68deg 64% 27%;
  --colour-andel-green-darkest-hsl: 68deg 64% 14%;
  --colour-error-h: 345deg;
  --colour-error-s: 100%;
  --colour-error-l: 39%;
  --colour-error-hsl: 345deg 100% 39%;
  --colour-error-light-hsl: 345deg 100% 55%;
  --colour-error-lighter-hsl: 345deg 100% 70%;
  --colour-error-lightest-hsl: 345deg 100% 85%;
  --colour-error-dark-hsl: 345deg 100% 30%;
  --colour-error-darker-hsl: 345deg 100% 20%;
  --colour-error-darkest-hsl: 345deg 100% 10%;
  --colour-success-h: 164deg;
  --colour-success-s: 100%;
  --colour-success-l: 29%;
  --colour-success-hsl: 164deg 100% 29%;
  --colour-success-light-hsl: 164deg 100% 47%;
  --colour-success-lighter-hsl: 164deg 100% 65%;
  --colour-success-lightest-hsl: 164deg 100% 82%;
  --colour-success-dark-hsl: 164deg 100% 22%;
  --colour-success-darker-hsl: 164deg 100% 15%;
  --colour-success-darkest-hsl: 164deg 100% 7%;
  --colour-info-h: 204deg;
  --colour-info-s: 100%;
  --colour-info-l: 40%;
  --colour-info-hsl: 204deg 100% 40%;
  --colour-info-light-hsl: 204deg 100% 55%;
  --colour-info-lighter-hsl: 204deg 100% 70%;
  --colour-info-lightest-hsl: 204deg 100% 85%;
  --colour-info-dark-hsl: 204deg 100% 30%;
  --colour-info-darker-hsl: 204deg 100% 20%;
  --colour-info-darkest-hsl: 204deg 100% 10%;
  --colour-warn-h: 44deg;
  --colour-warn-s: 100%;
  --colour-warn-l: 50%;
  --colour-warn-hsl: 44deg 100% 50%;
  --colour-warn-light-hsl: 44deg 100% 63%;
  --colour-warn-lighter-hsl: 44deg 100% 75%;
  --colour-warn-lightest-hsl: 44deg 100% 88%;
  --colour-warn-dark-hsl: 44deg 100% 38%;
  --colour-warn-darker-hsl: 44deg 100% 25%;
  --colour-warn-darkest-hsl: 44deg 100% 13%;
}

:root {
  --fontFamily: "Quicksand", sans-serif;
  --fontSize-standard: 1rem;
  --fontSize-xlarge: clamp(2rem, 10cqw, 5rem);
  --fontSize-large: clamp(1.25rem, 6cqw, 2rem);
  --fontSize-medium: clamp(1rem, 1.5vmin, 2.5rem);
  --fontSize-small: 0.8rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: var(--small) var(--xsmall);
  border: 1px solid hsl(var(--colour-background-darker-hsl));
  color: hsl(var(--colour-font-hsl));
  background: hsl(var(--colour-background-dark-hsl));
  font-weight: 700;
  border-radius: var(--borderRadius);
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: var(--xsmall);
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem hsl(var(--colour-andel-blue3-lighter-hsl));
}
.p-accordion .p-accordion-header:not(.p-disabled):not(.p-disabled):hover .p-accordion-header-link {
  border-color: hsl(var(--colour-background-darkest-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-content {
  padding: var(--small);
  border: 1px solid hsl(var(--colour-background-darker-hsl));
  background: hsl(var(--colour-background-hsl));
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion .p-accordion-tab {
  margin-bottom: var(--xsmall);
}

.p-badge {
  background: hsl(var(--colour-andel-blue3-hsl));
  color: hsl(var(--colour-background-hsl));
  font-size: calc(var(--fontSize-small) * 0.66);
  font-weight: 400;
  font-family: var(--fontFamily);
  min-width: calc(var(--fontSize-small) * 1.15);
  height: calc(var(--fontSize-small) * 1.15);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-overlay-badge .p-badge {
  transform: translate(50%, -50%);
}

.p-badge.p-badge-secondary {
  background-color: #64748b;
}

.p-badge.p-badge-success {
  background-color: hsl(var(--colour-success-hsl));
}

.p-badge.p-badge-info {
  background-color: hsl(var(--colour-info-hsl));
}

.p-badge.p-badge-warning {
  background-color: hsl(var(--colour-warn-hsl));
}

.p-badge.p-badge-danger {
  background-color: hsl(var(--colour-error-hsl));
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.menu-badge {
  overflow: visible;
}
.menu-badge .p-overlay-badge {
  position: absolute;
  left: var(--small);
  top: 0px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid hsl(var(--colour-font-hsl));
  background: hsl(var(--colour-background-dark-hsl));
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 16px;
  color: hsl(var(--colour-font-hsl));
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

:is(.p-multiselect-panel, .p-dropdown-panel) .p-checkbox .p-checkbox-box {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-andel-blue1-hsl));
  background: hsl(var(--colour-andel-blue1-hsl));
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--colour-warn-hsl);
  background: var(--colour-warn-hsl);
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: hsl(var(--colour-font-hsl));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: hsl(var(--colour-font-hsl));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: hsl(var(--colour-font-hsl));
  background: hsl(var(--colour-font-hsl));
  color: #ffffff;
}

.p-checkbox:is(.p-checkbox-disabled) {
  cursor: not-allowed;
}
.p-checkbox:is(.p-checkbox-disabled) .p-checkbox-box {
  border-color: hsl(var(--colour-andel-blue1-lighter-hsl));
  background: hsl(var(--colour-andel-blue1-lighter-hsl));
}

.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: hsl(var(--colour-error-hsl));
}

.p-checkbox.success .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-success-hsl));
  background-color: hsl(var(--colour-success-hsl));
}

.p-checkbox.error .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-error-hsl));
  background-color: hsl(var(--colour-error-hsl));
}

.p-datatable-auto-layout > .p-datatable-wrfonter {
  overflow-y: visible;
  z-index: 2;
}
.p-datatable-auto-layout > .p-datatable-wrfonter table {
  height: 1px;
}

.p-datatable-header {
  display: none;
}

.p-datatable-thead {
  border-bottom: 1px solid hsl(var(--colour-font-light-hsl));
  text-align: left;
}
.p-datatable-thead th {
  padding: 0px var(--small);
  font-size: var(--fontSize-small);
  text-align: left !important;
  color: hsl(var(--colour-font-light-hsl));
}
.p-datatable-thead th input,
.p-datatable-thead th .p-dropdown-label,
.p-datatable-thead th .p-multiselect-label {
  font-size: var(--fontSize-small);
  color: hsl(var(--colour-font-hsl));
}
.p-datatable-thead tr:first-of-type th {
  text-transform: uppercase;
}
.p-datatable-thead tr:last-of-type th {
  padding: var(--xsmall) var(--small) var(--small) var(--small);
}
.p-datatable-thead .p-column-header-content {
  flex-wrap: wrap;
}
.p-datatable-thead .p-column-header-content > span:first-of-type {
  flex: 1;
}
.p-datatable-thead .p-sortable-column {
  vertical-align: bottom;
}
.p-datatable-thead .p-column-header-content {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: "title title title" "sortOrder sort filter";
  row-gap: var(--xsmall);
}
.p-datatable-thead .p-column-header-content .p-column-title {
  grid-area: title;
  align-self: end;
}
.p-datatable-thead .p-column-header-content .p-sortable-column-icon {
  grid-area: sort;
}
.p-datatable-thead .p-column-header-content .p-column-filter {
  grid-area: filter;
}
.p-datatable-thead .p-sortable-column.p-highlight {
  color: hsl(var(--colour-andel-blue1-hsl));
}
.p-datatable-thead .p-sortable-column-badge {
  display: flex;
  align-items: center;
  height: var(--fontSize-small);
  width: var(--fontSize-small);
  background-color: hsl(var(--colour-andel-blue1-hsl));
  border-radius: 50%;
  font-size: 0.5rem;
  font-weight: 400;
  color: white;
}

.p-datatable-tbody tr {
  border-bottom: 1px solid hsl(var(--colour-font-lightest-hsl));
  line-height: 1.1;
  transition: background-color 150ms ease, color 150ms ease;
}
.p-datatable-tbody tr:hover {
  background-color: hsl(var(--colour-background-dark-hsl));
}
.p-datatable-tbody tr.p-highlight {
  position: relative;
  overflow: hidden;
  background-color: hsl(var(--colour-background-dark-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}
.p-datatable-tbody tr.p-highlight::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 6px;
  background-color: hsl(var(--colour-andel-blue3-hsl));
}
.p-datatable-tbody td {
  padding: var(--small);
}
.p-datatable-tbody td span {
  display: inline-flex;
  align-items: center;
}
.p-datatable-tbody td img {
  height: 1.25rem;
}

.p-filter-column input {
  width: min(16ch, 100%);
}

.p-filter-column.customDynamicFilter input {
  width: min(24ch, 100%);
}

.p-filter-column.timestampFilter :is(span, .p-calendar) {
  display: block;
}
.p-filter-column.timestampFilter :is(span, .p-calendar) :is(input, .p-inputtext) {
  width: min(36ch, 100%);
  cursor: pointer;
}

.p-paginator {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-font-hsl));
  color: hsl(var(--colour-font-light-hsl));
}

.p-paginator-pages {
  display: flex;
  gap: 8px;
  margin: 0 16px;
}

.p-paginator-page.p-highlight {
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-paginator-current {
  margin-left: auto;
  line-height: 1.4;
}

.hideExpander .p-row-toggler {
  display: none;
}

.p-row-expanded td {
  padding-left: 0;
  padding-bottom: 0;
}

.datatableItem {
  align-items: center;
  width: max-content;
  padding: var(--xsmall) var(--small);
  border-radius: calc(var(--xsmall) + var(--fontSize-small));
  color: hsl(var(--colour-background-hsl));
}
.datatableItem.success {
  background-color: hsl(var(--colour-success-hsl));
}
.datatableItem.error {
  background-color: hsl(var(--colour-error-hsl));
}

.p-datepicker {
  max-width: 400px;
  margin-top: 2px;
  padding: var(--small);
  background: hsl(var(--colour-background-hsl));
  color: hsl(var(--colour-font-hsl));
  border-radius: var(--borderRadius);
  font-size: 0.75rem;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: hsl(var(--colour-background-hsl));
  border: 0 none;
  box-shadow: var(--box-shadow);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: hsl(var(--colour-background-hsl));
}

.p-datepicker .p-datepicker-header {
  padding: 8px;
  background: hsl(var(--colour-background-hsl));
  border-bottom: 1px solid hsl(var(--colour-background-darkest-hsl));
  font-weight: 700;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next) {
  width: 2rem;
  height: 2rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 350ms, color 350ms;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next):enabled:hover {
  border-color: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 8px;
}

.p-datepicker .p-datepicker-header .p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 350ms, color 350ms, border-color 350ms;
}

.p-datepicker table {
  margin: 0.5rem 0;
}
.p-datepicker table th,
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table th > span,
.p-datepicker table td > span {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background-color 350ms ease;
}
.p-datepicker table td > span.p-highlight {
  background: hsl(var(--colour-andel-blue3-hsl));
  color: hsl(var(--colour-background-hsl));
}
.p-datepicker table td > span.p-disabled {
  color: hsl(var(--colour-font-lighter-hsl));
}
.p-datepicker table td.p-datepicker-today > span {
  border: 1px solid hsl(var(--colour-font-hsl));
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid hsl(var(--colour-background-darkest-hsl));
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
  background-color: hsl(var(--colour-background-hsl));
}

.p-datepicker-timeonly .p-timepicker {
  font-size: var(--fontMedium);
}

.p-calendar .p-inputtext:read-only:not(.p-disabled) {
  cursor: pointer;
  background-color: hsl(var(--colour-background-h), var(--colour-background-s), var(--colour-background-l));
  color: hsl(var(--colour-font-hsl));
}

.p-timepicker {
  margin-top: var(--medium);
  font-size: 2rem;
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(6px);
}

.p-dialog {
  padding: 32px;
  width: var(--dialogWidth);
  background-color: hsl(var(--colour-background-hsl));
  border: 3px solid hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  color: hsl(var(--colour-font-hsl));
}

.p-dialog-header {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: var(--small);
  padding-bottom: var(--small);
}
.p-dialog-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 60px;
  background-color: hsl(var(--colour-andel-blue3-hsl));
  border-radius: 2px;
}

.p-dialog-title {
  font-size: 1.5rem;
}

.p-dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: var(--medium);
}

.p-dialog-header-icon {
  color: hsl(var(--colour-andel-blue1-hsl));
  transition: color 350ms ease;
}
.p-dialog-header-icon:hover {
  color: hsl(var(--colour-andel-green-hsl));
}

.p-dialog-content {
  max-height: 70vh;
  padding: 32px 0px;
  overflow-y: auto;
}

.p-dialog:not(:has(.p-dialog-footer)) .p-dialog-content {
  padding-bottom: 0;
}

.loadingDialog {
  color: black;
}

.dialogContentMaxHeight {
  max-height: 40vh;
  overflow-y: auto;
}

.p-dropdown,
.p-multiselect {
  display: inline-flex;
  align-items: center;
  height: calc(var(--medium) * 1.5);
  width: auto;
  padding: 0 var(--small);
  background-color: hsl(var(--colour-background-hsl));
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-font-hsl));
  transition: border-color 350ms ease;
}
.p-dropdown:hover,
.p-multiselect:hover {
  border-color: hsl(var(--colour-font-hsl));
}
.p-dropdown:focus, .p-dropdown.p-inputwrapper-focus,
.p-multiselect:focus,
.p-multiselect.p-inputwrapper-focus {
  border-color: hsl(var(--colour-andel-blue3-hsl));
}
.p-dropdown.p-error,
.p-multiselect.p-error {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}

.p-dropdown-clear-icon,
.p-multiselect-clear-icon {
  position: relative;
  margin: 0;
  top: auto;
  padding-right: 8px;
  order: -1;
}

.p-dropdown-label,
.p-multiselect-label {
  color: hsl(var(--colour-font-hsl));
  font-weight: 400;
}

.p-dropdown-trigger,
.p-multiselect-trigger {
  padding-left: 16px;
  color: hsl(var(--colour-font-hsl));
}

.p-dropdown-panel,
.p-multiselect-panel,
.p-autocomplete-panel {
  margin-top: 2px;
  background-color: hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.p-dropdown-item,
.p-multiselect-item,
.p-autocomplete-item {
  display: flex;
  align-items: center;
  padding: var(--small) var(--small);
  transition: background-color 350ms ease;
  color: hsl(var(--colour-font-hsl));
}
.p-dropdown-item:hover:not(.p-disabled),
.p-multiselect-item:hover:not(.p-disabled),
.p-autocomplete-item:hover:not(.p-disabled) {
  background-color: hsl(var(--colour-andel-blue1-lightest-hsl));
}
.p-dropdown-item.p-disabled,
.p-multiselect-item.p-disabled,
.p-autocomplete-item.p-disabled {
  color: hsl(var(--colour-font-lighter-hsl));
  cursor: not-allowed !important;
}
.p-dropdown-item img,
.p-multiselect-item img,
.p-autocomplete-item img {
  height: 1.25rem;
}

.p-multiselect-header {
  padding: var(--small) var(--small);
  background-color: hsl(var(--colour-background-darker-hsl));
  border-bottom: 1px solid hsl(var(--colour-background-darkest-hsl));
}

.p-multiselect-close {
  color: hsl(var(--colour-andel-blue1-hsl));
  transition: color 350ms ease;
}
.p-multiselect-close:hover {
  color: hsl(var(--colour-andel-green-hsl));
}

.p-dropdown.p-disabled,
.p-multiselect.p-disabled {
  pointer-events: auto;
  background-color: hsl(var(--colour-background-dark-hsl));
  border-color: hsl(var(--colour-font-lightest-hsl));
  color: hsl(var(--colour-font-lighter-hsl));
}
.p-dropdown.p-disabled:is(:hover, :focus),
.p-multiselect.p-disabled:is(:hover, :focus) {
  border-color: hsl(var(--colour-font-lightest-hsl));
}
.p-dropdown.p-disabled .p-dropdown-label,
.p-dropdown.p-disabled .p-dropdown-trigger,
.p-dropdown.p-disabled .p-multiselect-label,
.p-dropdown.p-disabled .p-multiselect-trigger,
.p-multiselect.p-disabled .p-dropdown-label,
.p-multiselect.p-disabled .p-dropdown-trigger,
.p-multiselect.p-disabled .p-multiselect-label,
.p-multiselect.p-disabled .p-multiselect-trigger {
  color: hsl(var(--colour-font-lighter-hsl));
}

.p-dropdown-filter-container,
.p-multiselect-filter-container {
  display: flex;
  margin: var(--xsmall) var(--small);
}
.p-dropdown-filter-container .p-dropdown-filter-icon,
.p-dropdown-filter-container .p-multiselect-filter-icon,
.p-multiselect-filter-container .p-dropdown-filter-icon,
.p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: hsl(var(--colour-font-hsl));
}

.p-multiselect-filter-container {
  margin-left: 0;
}

.p-multiselect-label {
  display: flex;
  gap: var(--small);
}

.feature:is(.p-dropdown, .p-multiselect) {
  border-color: hsl(var(--colour-andel-blue1-hsl));
  background-color: hsl(var(--colour-andel-blue1-hsl));
  box-shadow: var(--box-shadow);
}
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-label,
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-trigger {
  color: #fff;
}

.p-fileupload {
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
}

.p-fileupload-buttonbar {
  display: flex;
  column-gap: var(--small);
  padding: var(--small);
  background-color: hsl(var(--colour-background-darker-hsl));
}

.p-fileupload-content {
  padding: var(--small);
}

.formField .p-inputswitch {
  margin: auto 0;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background-color: hsl(var(--colour-font-lightest-hsl));
  transition: background-color 0.2s, color 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-font-dark-hsl));
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: hsl(var(--colour-font-lightest-hsl));
}

[data-theme=dark] .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  --colour-font-lightest-hsl: var(--colour-font-darkest-hsl);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-andel-blue3-hsl));
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px hsl(var(--colour-background-darker-hsl));
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-font-darkest-hsl));
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: hsl(var(--colour-andel-blue1-dark-hsl));
}

.p-menu-overlay {
  margin-top: 8px;
  background-color: hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  border: 1px solid hsl(var(--colour-font-light-hsl));
}

.p-menu {
  min-width: 12rem;
  padding: var(--small) 0px;
}
.p-menu .p-menu-separator {
  border-top: 1px solid hsl(var(--colour-font-lightest-hsl));
  margin: var(--xsmall) var(--small);
}

.p-menuitem {
  list-style: none;
}
.p-menuitem + .p-submenu-header {
  padding-top: var(--small);
}

.p-submenu-header {
  list-style: none;
  padding: var(--xsmall) var(--small);
  font-weight: 700;
  color: hsl(var(--colour-fontHSL));
}

.p-menuitem-link {
  padding: var(--xsmall) var(--small);
  background-color: transparent;
  color: hsl(var(--colour-font-hsl));
  transition: color 350ms ease;
}
.p-menuitem-link:not(.p-disabled):hover {
  color: hsl(var(--colour-andel-green-hsl));
}

.p-menuitem-icon {
  margin-right: var(--small);
}

.flat .p-submenu-header {
  display: none;
}

.p-picklist {
  gap: var(--small);
}

.p-picklist .p-picklist-buttons {
  gap: var(--xsmall);
}

.p-picklist .p-picklist-header {
  padding: var(--small);
  background-color: hsl(var(--colour-background-dark-hsl));
  border: 1px solid hsl(var(--colour-font-lightest-hsl));
  border-bottom: 0 none;
  border-top-right-radius: var(--borderRadius);
  border-top-left-radius: var(--borderRadius);
  font-weight: 700;
}

.p-picklist .p-picklist-filter-container {
  padding: var(--small);
  padding-top: 0;
  background-color: hsl(var(--colour-background-dark-hsl));
  border: 1px solid hsl(var(--colour-font-lightest-hsl));
  border-block: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: var(--large);
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: var(--small);
}

.p-picklist .p-picklist-list {
  border: 1px solid hsl(var(--colour-font-lightest-hsl));
  background: #ffffff;
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: var(--xsmall) var(--small);
  margin: 0;
  border: 0 none;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item [data-allow-edit="1"] {
  pointer-events: none;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background-color: hsl(var(--colour-background-dark-hsl));
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.1rem hsl(var(--colour-andel-blue4-light-hsl));
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: hsl(var(--colour-andel-blue3-hsl));
  background: hsl(var(--colour-andel-blue4-lightest-hsl));
}

.p-skeleton {
  min-width: 100px;
  background-color: hsl(var(--colour-background-darker-hsl));
}
.p-skeleton:not(.p-skeleton-circle) {
  border-radius: var(--borderRadius);
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.paginatorSkeleton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-font-hsl));
}

.p-steps .p-steps-item .p-menuitem-link {
  background: hsl(var(--colour-background-hsl));
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  z-index: 1;
  height: 2rem;
  width: 2rem;
  background: hsl(var(--colour-background-hsl));
  border: 2px solid hsl(var(--colour-font-light-hsl));
  border-radius: 50%;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 700;
  color: hsl(var(--colour-font-light-hsl));
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: hsl(var(--colour-font-hsl));
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: hsl(var(--colour-andel-blue3-hsl));
  color: hsl(var(--colour-background-hsl));
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid hsl(var(--colour-font-light-hsl));
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabview .p-tabview-nav {
  --border-width: 4px;
  background: #ffffff;
  border: solid hsl(var(--colour-background-darkest-hsl));
  border-width: 0 0 var(--border-width) 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 var(--border-width) 0;
  border-color: transparent transparent hsl(var(--colour-font-lighter-hsl)) transparent;
  background: #ffffff;
  color: hsl(var(--colour-font-lighter-hsl));
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 calc(-1 * var(--border-width)) 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #bfdbfe;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  color: hsl(var(--colour-font-hsl));
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: hsl(var(--colour-andel-blue3-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: red;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #bfdbfe;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  border: 0 none;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: var(--border-width);
  background-color: hsl(var(--colour-andel-blue3-hsl));
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.tabItem-header {
  cursor: pointer;
  padding-inline: var(--medium);
  padding-block: var(--small);
}

.p-timeline {
  display: flex;
  flex-direction: column;
}
.p-timeline.p-timeline-vertical .p-timeline-event {
  column-gap: var(--small);
}

.p-timeline-event-opposite {
  flex: 0 0 auto;
  padding: 0;
  padding-bottom: var(--medium);
}

.p-timeline-event-content {
  flex: 1;
  padding: 0;
  padding-bottom: var(--medium);
}

.p-timeline-event-connector {
  flex-grow: 1;
  width: 2px;
  background-color: hsl(var(--colour-background-darker-hsl));
}

.customTimelineMarker {
  display: flex;
  place-items: center;
  place-content: center;
  align-self: baseline;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: hsl(var(--colour-andel-blue1-hsl));
  color: hsl(var(--colour-background-hsl));
}
.customTimelineMarker i {
  width: max-content;
  font-size: 0.75rem;
}

.p-toast {
  z-index: 999999 !important;
}
.p-toast > div {
  display: flex;
  flex-direction: column;
  gap: var(--small);
}
.p-toast .p-toast-icon-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: inherit;
}
.p-toast .p-toast-message {
  position: relative;
  padding: var(--small) var(--medium) var(--small) var(--small);
  background-color: hsl(var(--colour-background-hsl));
  border-style: solid;
  border-width: 1px;
  border-left-width: var(--xsmall);
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
.p-toast .p-toast-message-content {
  display: flex;
  align-items: center;
  padding: 0;
}
.p-toast .p-toast-message.p-toast-message-success {
  border-color: hsl(var(--colour-success-hsl));
  color: hsl(var(--colour-success-hsl));
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close:hover {
  color: hsl(var(--colour-success-hsl));
}
.p-toast .p-toast-message.p-toast-message-error {
  border-color: hsl(var(--colour-error-hsl));
  color: hsl(var(--colour-error-hsl));
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close:hover {
  color: hsl(var(--colour-error-hsl));
}
.p-toast .p-toast-message.p-toast-message-info {
  border-color: hsl(var(--colour-info-hsl));
  color: hsl(var(--colour-info-hsl));
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close:hover {
  color: hsl(var(--colour-info-hsl));
}
.p-toast .p-toast-message.p-toast-message-warn {
  border-color: hsl(var(--colour-warn-hsl));
  color: hsl(var(--colour-warn-hsl));
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close:hover {
  color: hsl(var(--colour-warn-hsl));
}

.p-toast-message-icon {
  margin-right: var(--small);
  font-size: var(--fontSize-large);
}

.p-toast-summary {
  font-size: var(--fontSize-medium);
  font-weight: 700;
}

.p-tooltip {
  --tooltip-background: hsl(var(--colour-andel-blue1-hsl));
  --tooltip-border: hsl(var(--colour-andel-blue1-hsl));
  --tooltip-text: hsl(var(--colour-background-hsl));
  max-width: 400px;
}
.p-tooltip.header {
  --tooltip-background: hsl(var(--colour-background-hsl));
  --tooltip-text: hsl(var(--colour-font-hsl));
}
.p-tooltip .p-tooltip-text {
  background: var(--tooltip-background);
  border: 1px solid var(--tooltip-border);
  color: var(--tooltip-text);
  padding: var(--xsmall);
  box-shadow: var(--box-shadow);
  border-radius: var(--borderRadius);
  font-size: var(--fontSize-small);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: var(--tooltip-border);
}

.withTooltip {
  position: relative;
  cursor: pointer;
}
.withTooltip::after {
  z-index: 1000;
  content: attr(aria-label);
  position: absolute;
  width: max-content;
  top: 110%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: var(--xsmall) var(--small);
  background: hsl(var(--colour-font-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-background-hsl));
  font-family: var(--font);
  font-size: var(--fontSmall);
  opacity: 0;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0ms ease-in-out 100ms, opacity 100ms linear;
}
.withTooltip:hover::after {
  opacity: 1;
  transform: scale(1);
  transition: transform 100ms ease-in-out, opacity 100ms linear;
}

.p-tree:not(:has(.p-treenode-droppoint)) .p-tree-container .p-treenode {
  padding-block: 0.25rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: var(--borderRadius);
  transition: box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: var(--xsmall);
  width: 2rem;
  height: 2rem;
  color: hsl(var(--colour-font-lighter-hsl));
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: hsl(var(--colour-andel-blue3-hsl));
  border-color: transparent;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem hsl(var(--colour-andel-blue3-lightest-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: hsl(var(--colour-font-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem hsl(var(--colour-andel-blue3-lighter-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: hsl(var(--colour-background-dark-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: hsl(var(--colour-background-dark-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: hsl(var(--colour-background-dark-hsl));
  color: hsl(var(--colour-andel-blue3-hsl));
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: hsl(var(--colour-font-hsl));
}

.p-tree .p-treenode-children {
  margin: 0 0 0 3.5rem;
  border-left: 1px solid hsl(var(--colour-andel-blue1-lightest-hsl));
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint {
  height: 0.5rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #8cbeff;
}

button:not(.p-link, .card, .noStyle),
.p-button:not(.p-link, .card, .noStyle),
.button:not(.p-link, .card, .noStyle),
input[type=submit]:not(.p-link, .card, .noStyle) {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--medium) * 1.5);
  min-width: max-content;
  column-gap: var(--xsmall);
  padding: 0 var(--medium);
  background-color: hsl(var(--colour-background-hsl));
  border: 2px solid hsl(var(--colour-andel-blue3-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-andel-blue3-hsl));
  font-weight: 700;
  font-size: var(--fontSize-standard);
  font-family: var(--fontFamily);
  transition: color 350ms ease, border-color 350ms ease, background-color 500ms ease, transform 350ms ease;
}
button:not(.p-link, .card, .noStyle) span,
.p-button:not(.p-link, .card, .noStyle) span,
.button:not(.p-link, .card, .noStyle) span,
input[type=submit]:not(.p-link, .card, .noStyle) span {
  z-index: 2;
}
button:not(.p-link, .card, .noStyle):hover:not(.p-disabled, :disabled, .p-inputnumber-button),
.p-button:not(.p-link, .card, .noStyle):hover:not(.p-disabled, :disabled, .p-inputnumber-button),
.button:not(.p-link, .card, .noStyle):hover:not(.p-disabled, :disabled, .p-inputnumber-button),
input[type=submit]:not(.p-link, .card, .noStyle):hover:not(.p-disabled, :disabled, .p-inputnumber-button) {
  transform: scale(1.02);
}
button:not(.p-link, .card, .noStyle):active:not(.p-disabled, :disabled, .p-inputnumber-button),
.p-button:not(.p-link, .card, .noStyle):active:not(.p-disabled, :disabled, .p-inputnumber-button),
.button:not(.p-link, .card, .noStyle):active:not(.p-disabled, :disabled, .p-inputnumber-button),
input[type=submit]:not(.p-link, .card, .noStyle):active:not(.p-disabled, :disabled, .p-inputnumber-button) {
  transform: scale(0.9);
}
button:not(.p-link, .card, .noStyle).success,
.p-button:not(.p-link, .card, .noStyle).success,
.button:not(.p-link, .card, .noStyle).success,
input[type=submit]:not(.p-link, .card, .noStyle).success {
  --colour-andel-blue3-hsl: var(--colour-success-hsl);
  border-color: hsl(var(--colour-success-hsl));
  color: hsl(var(--colour-success-hsl));
}
button:not(.p-link, .card, .noStyle).error,
.p-button:not(.p-link, .card, .noStyle).error,
.button:not(.p-link, .card, .noStyle).error,
input[type=submit]:not(.p-link, .card, .noStyle).error {
  --colour-andel-blue3-hsl: var(--colour-error-hsl);
  border-color: hsl(var(--colour-error-hsl));
  color: hsl(var(--colour-error-hsl));
}
button:not(.p-link, .card, .noStyle).feature,
.p-button:not(.p-link, .card, .noStyle).feature,
.button:not(.p-link, .card, .noStyle).feature,
input[type=submit]:not(.p-link, .card, .noStyle).feature {
  background-color: hsl(var(--colour-andel-blue3-hsl));
  box-shadow: var(--box-shadow);
  color: hsl(var(--colour-background-hsl));
}
button:not(.p-link, .card, .noStyle).feature:is(.p-disabled, :disabled),
.p-button:not(.p-link, .card, .noStyle).feature:is(.p-disabled, :disabled),
.button:not(.p-link, .card, .noStyle).feature:is(.p-disabled, :disabled),
input[type=submit]:not(.p-link, .card, .noStyle).feature:is(.p-disabled, :disabled) {
  color: hsl(var(--colour-background-darkest-hsl));
}
button:not(.p-link, .card, .noStyle).secondary,
.p-button:not(.p-link, .card, .noStyle).secondary,
.button:not(.p-link, .card, .noStyle).secondary,
input[type=submit]:not(.p-link, .card, .noStyle).secondary {
  border-color: hsl(var(--colour-secondary-hsl));
  color: hsl(var(--colour-secondary-hsl));
}
button:not(.p-link, .card, .noStyle).rounded,
.p-button:not(.p-link, .card, .noStyle).rounded,
.button:not(.p-link, .card, .noStyle).rounded,
input[type=submit]:not(.p-link, .card, .noStyle).rounded {
  width: calc(var(--medium) * 1.5);
  padding: 0;
  border-radius: 50%;
}
button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):is(.feature),
.p-button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):is(.feature),
.button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):is(.feature),
input[type=submit]:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):is(.feature) {
  filter: saturate(40%) brightness(120%);
}
button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):not(.feature),
.p-button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):not(.feature),
.button:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):not(.feature),
input[type=submit]:not(.p-link, .card, .noStyle):is(.p-disabled, :disabled, [disabled]):not(.feature) {
  border-color: hsl(var(--colour-andel-blue1-lighter-hsl));
  color: hsl(var(--colour-andel-blue1-lighter-hsl));
  filter: saturate(60%);
}
button:not(.p-link, .card, .noStyle).noBorder,
.p-button:not(.p-link, .card, .noStyle).noBorder,
.button:not(.p-link, .card, .noStyle).noBorder,
input[type=submit]:not(.p-link, .card, .noStyle).noBorder {
  border: none;
}
button:not(.p-link, .card, .noStyle).inline,
.p-button:not(.p-link, .card, .noStyle).inline,
.button:not(.p-link, .card, .noStyle).inline,
input[type=submit]:not(.p-link, .card, .noStyle).inline {
  height: auto;
  padding: 0;
}
button:not(.p-link, .card, .noStyle).appNav,
.p-button:not(.p-link, .card, .noStyle).appNav,
.button:not(.p-link, .card, .noStyle).appNav,
input[type=submit]:not(.p-link, .card, .noStyle).appNav {
  background: transparent;
  border: none;
  color: hsl(var(--colour-background-hsl));
  font-size: var(--fontLarge);
}
button.noStyle,
.p-button.noStyle,
.button.noStyle,
input[type=submit].noStyle {
  background: none;
  border: none;
  padding: 0;
}

button.p-button.p-inputnumber-button {
  height: auto;
  border-width: 1px;
  transition: background-color 350ms ease;
}
button.p-button.p-inputnumber-button:hover {
  background-color: hsl(var(--colour-andel-blue2-lightest-hsl));
}
button.p-button.p-inputnumber-button:active {
  background-color: hsl(var(--colour-andel-blue3-lightest-hsl));
}

.p-button-icon-only:not(.rounded) {
  padding: 0 var(--small);
}
.p-button-icon-only .p-button-label {
  display: none;
}

.appNav .p-button {
  padding: 0;
  background: transparent;
  border: none;
  color: hsl(var(--colour-background-hsl));
  font-weight: normal;
}
.appNav .p-button:hover {
  color: hsl(var(--colour-andel-green-hsl));
}

.subtitle .p-button {
  --hover-colour: hsl(var(--colour-app-h) var(--colour-app-s) calc(var(--colour-app-l) * 1.1));
  transform: scale(0);
  border: none;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc(var(--colour-font-l) * 1.66));
  transition: transform 350ms ease, background-color 350ms ease;
}
.subtitle .p-button.error {
  --hover-colour: hsl(var(--colour-error-h) var(--colour-error-s) calc(var(--colour-error-l) * 1.85));
}
.subtitle .p-button:hover {
  background-color: var(--hover-colour);
}
.subtitle:hover .p-button {
  transform: scale(1);
}

.card {
  padding: var(--medium);
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
.card header {
  position: relative;
}
.card header h3 {
  min-width: 0px;
  word-wrap: break-word;
}
.card header:not(.no-divider):not([class^=marginBottom]) {
  margin-bottom: var(--medium);
  padding-bottom: var(--xsmall);
}
.card header:not(.no-divider):not([class^=marginBottom])::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 60px;
  background-color: hsl(var(--colour-andel-blue3-hsl));
  border-radius: 2px;
}

[data-theme=dark] .card {
  background-color: hsl(var(--colour-background-dark-hsl));
}

.warnBorder {
  border: 3px solid hsl(var(--colour-warn-hsl));
}

.chartContainer {
  min-height: 600px;
}

.summaryChart-wrapper {
  display: grid;
  grid-template-areas: "centered";
  justify-items: center;
  align-items: center;
}
.summaryChart-wrapper > * {
  grid-area: centered;
}

.summaryChart-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  aspect-ratio: 1/1;
  font-size: var(--fontSize-large);
  font-weight: 700;
  line-height: 1;
}

.field-value {
  justify-self: start;
  color: hsl(var(--colour-font-hsl));
}
.field-value div {
  transition: color 2s ease;
}

.fieldBoolean {
  padding: calc(var(--xsmall) / 2) var(--small);
  border-radius: 1rem;
  color: hsl(var(--colour-background-hsl));
  font-size: var(--fontSize-small);
  transition: background-color 500ms ease;
}
.fieldBoolean-true {
  background-color: hsl(var(--colour-success-hsl));
}
.fieldBoolean-false {
  background-color: hsl(var(--colour-error-hsl));
}
.fieldBoolean-undefined {
  background-color: hsl(var(--colour-warn-hsl));
}

form,
.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--medium);
}

.formField {
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--xsmall) / 2);
}

label {
  font-size: var(--fontSize-small);
  font-weight: 700;
  transition: color 350ms ease;
}

.formField:has(:is(input:focus, textarea:focus)) label {
  color: hsl(var(--colour-andel-blue3-hsl));
}

.formField:has(.p-disabled) :is(label, i) {
  color: hsl(var(--colour-font-lighter-hsl));
}

.formField:has(.p-error) label {
  color: hsl(var(--colour-error-hsl));
}

input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext),
.p-inputtextarea {
  height: calc(var(--medium) * 1.5);
  width: 100%;
  padding: 0 var(--small);
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
  background-color: hsl(var(--colour-background-hsl));
  font-family: var(--fontFamily);
  font-size: var(--fontSize--standard);
  color: hsl(var(--colour-font-hsl));
  transition: colour 350ms ease, border 350ms ease;
  /* Change the white to any color */
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):is(:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active),
.p-inputtextarea:is(:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active) {
  -webkit-box-shadow: 0 0 0 calc(var(--medium) * 1.5) hsl(var(--colour-background-light-hsl)) inset !important;
  -webkit-text-fill-color: hsl(var(--colour-font-hsl)) !important;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):hover,
.p-inputtextarea:hover {
  border-color: hsl(var(--colour-font-hsl));
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):focus,
.p-inputtextarea:focus {
  border-color: hsl(var(--colour-andel-blue3-hsl));
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext).p-error,
.p-inputtextarea.p-error {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):is(:read-only, :disabled),
.p-inputtextarea:is(:read-only, :disabled) {
  cursor: not-allowed;
  background-color: hsl(var(--colour-font-h) var(--colour-font-s) var(--colour-font-l)/0.1);
  color: hsl(var(--colour-font-lighter-hsl));
}

.p-inputtextarea {
  height: auto;
  padding-block: var(--small);
  font-family: var(--font);
}

.p-password.p-error input {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}

.p-input-icon-right input {
  padding-right: var(--large);
}
.p-input-icon-right i {
  right: var(--small);
}
.p-input-icon-right.p-error i {
  right: var(--medium);
}
.p-input-icon-right.p-password i {
  cursor: pointer;
}

.loadingDialog {
  width: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.loadingDialog .p-dialog-content {
  background-color: transparent;
  text-align: center;
}
.loadingDialog .message {
  margin-top: 32px;
  font-size: var(--fontSize-large);
  font-weight: bold;
  text-transform: uppercase;
  color: hsl(var(--colour-font-darkest-hsl));
}

.loadingDot {
  animation-name: loadingDot;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.loadingDot:nth-child(1) {
  animation-delay: 0.5s;
}

.loadingDot:nth-child(2) {
  animation-delay: 1s;
}

.p-progress-spinner-circle {
  animation-duration: 1.5s, 3s;
}

@keyframes p-progress-spinner-color {
  100%, 0% {
    stroke: hsl(var(--colour-andel-green-hsl));
  }
  50% {
    stroke: hsl(var(--colour-andel-blue1-hsl));
  }
}
@keyframes loadingDot {
  100%, 0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
#logo {
  height: 100%;
}
#logo #andel-text {
  fill: hsl(var(--colour-andel-grey-hsl));
}
#logo #left-1 {
  fill: hsl(var(--colour-andel-blue4-hsl));
}
#logo #left-2 {
  fill: hsl(var(--colour-andel-blue3-hsl));
}
#logo #right-1 {
  fill: hsl(var(--colour-andel-blue2-hsl));
}
#logo #right-2 {
  fill: hsl(var(--colour-andel-blue1-hsl));
}
#logo #left-3 {
  fill: hsl(var(--colour-andel-green-hsl));
}
#logo #right-3 {
  fill: hsl(var(--colour-andel-green2-hsl));
}
#logo.guest {
  width: 87%;
  margin-inline: auto;
}

.appHeader #logo #andel-text {
  fill: hsl(var(--colour-background-hsl));
}
.appHeader #logo #left-1 {
  fill: hsl(var(--colour-background-hsl));
}
.appHeader #logo #left-2 {
  fill: hsl(var(--colour-background-hsl));
}
.appHeader #logo #right-1 {
  fill: hsl(var(--colour-background-hsl));
}
.appHeader #logo #right-2 {
  fill: hsl(var(--colour-background-hsl));
}
.appHeader #logo #left-3,
.appHeader #logo #right-3 {
  fill: hsl(var(--colour-background-hsl));
}

@media screen and (max-height: 1000px) {
  #logo.guest {
    width: 60%;
  }
}
.pageHeader {
  grid-area: pageHeader;
  position: relative;
  padding-top: var(--large);
  padding-bottom: var(--medium);
  background-color: hsl(var(--colour-andel-blue1-hsl));
}
.pageHeader h1 {
  font-size: 3.5rem;
  color: hsl(var(--colour-background-hsl));
}
.pageHeader h2 {
  font-size: 1.75rem;
  color: hsl(var(--colour-andel-blue1-lighter-hsl));
  text-transform: uppercase;
}
.pageHeader .goBack {
  position: absolute;
  bottom: 0;
  right: 102%;
  font-size: var(--fontSize-large);
  color: hsl(var(--colour-andel-blue1-lighter-hsl));
}
.pageHeader .goBack:hover {
  color: hsl(var(--colour-andel-green-hsl));
}

.pageHeader-graphic {
  --graphicsOffset: 6vmin;
  --accentColour: var(--colour-andel-green-hsl);
  grid-area: pageHeaderGraphic;
  height: var(--graphicsOffset);
  width: 100%;
}
.pageHeader-graphic svg {
  width: 100%;
}
.pageHeader-graphic svg .curve-blue1 {
  fill: hsl(var(--colour-andel-blue1-hsl));
}
.pageHeader-graphic svg .curve-blue2 {
  fill: hsl(var(--colour-andel-blue2-hsl));
}
.pageHeader-graphic svg .curve-green {
  fill: hsl(var(--accentColour));
}

.pendingUpdate {
  padding: var(--small);
  background-color: hsl(var(--colour-andel-green-lightest-hsl));
  border: 3px solid hsl(var(--colour-andel-green-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-andel-green-dark-hsl));
}

.pillItem {
  align-items: center;
  width: max-content;
  padding: var(--xsmall) var(--small);
  border-radius: calc(var(--xsmall) + var(--fontSize-small));
  color: hsl(var(--colour-background-hsl));
}
.pillItem span {
  line-height: 1;
}
.pillItem.blue1 {
  background-color: hsl(var(--colour-andel-blue1-hsl));
}
.pillItem.blue2 {
  background-color: hsl(var(--colour-andel-blue2-hsl));
}
.pillItem.blue3 {
  background-color: hsl(var(--colour-andel-blue3-hsl));
}
.pillItem.blue4 {
  background-color: hsl(var(--colour-andel-blue4-hsl));
}
.pillItem.green {
  background-color: hsl(var(--colour-andel-green-hsl));
}
.pillItem.success {
  background-color: hsl(var(--colour-success-hsl));
}
.pillItem.error {
  background-color: hsl(var(--colour-error-hsl));
}
.pillItem.info {
  background-color: hsl(var(--colour-info-hsl));
}
.pillItem.warn {
  background-color: hsl(var(--colour-warn-hsl));
}

.zoneGrid {
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-areas: "nodes details";
  column-gap: 0;
}
.zoneGrid.withDetails {
  grid-template-columns: 1fr 1fr;
  column-gap: var(--large);
}

.zoneNodes {
  grid-area: nodes;
}

.zoneDetails {
  grid-area: details;
}

.p-column-filter-menu-button span.pi-filter {
  z-index: 2;
  color: hsl(var(--colour-font-hsl));
}

.p-column-filter-menu-button-open span.pi-filter {
  color: hsl(var(--colour-andel-blue1-hsl));
}

.p-column-filter-menu-button-active span.pi-filter {
  color: hsl(var(--colour-background-hsl));
}

.p-column-filter-overlay {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: hsla(var(--colour-background-h), var(--colour-background-s), var(--colour-background-l), 0.8);
  border-radius: calc(var(--borderRadius) * 2);
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  background-clip: padding-box;
  overflow: hidden;
}

.p-column-filter-operator,
.p-column-filter-constraints,
.p-column-filter-add-rule,
.p-column-filter-buttonbar {
  padding: var(--small);
}

.p-column-filter-operator {
  padding: var(--xsmall var(--small));
  background-color: hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.1);
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-constraints {
  display: flex;
  flex-direction: column;
  gap: var(--small);
}
.p-column-filter-constraints .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: var(--xsmall);
}
.p-column-filter-constraints input + div:not(.p-component) {
  display: flex;
  justify-content: center;
}

.p-column-filter-buttonbar {
  order: -1;
  gap: var(--small);
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-add-rule {
  display: flex;
  justify-content: center;
  padding-top: 0;
}

.p-button.p-column-filter-add-button,
.p-button.p-column-filter-remove-button {
  height: 1.5rem;
  border: none;
  background: transparent;
  font-size: var(--fontSize-small);
  color: hsl(var(--colour-success-hsl));
}

.p-button.p-column-filter-remove-button {
  display: flex;
  margin: var(--small) auto 0;
  color: hsl(var(--colour-error-hsl));
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: white;
  overflow: visible;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active::before,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover::before {
  --oversize: 100%;
  content: "";
  position: absolute;
  top: calc(var(--oversize) * -0.5);
  left: calc(var(--oversize) * -0.5);
  height: calc(100% + var(--oversize));
  aspect-ratio: 1 !important;
  background-color: hsl(var(--colour-andel-blue1-hsl));
  opacity: 1;
  transform: scale(1);
  border-radius: 50%;
}

.colourRow {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: var(--medium);
}

.colourWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.colourWrapper:not(.colourWrapper-main) .colourSquare {
  width: 100%;
  aspect-ratio: 2/1;
}

.colourWrapper-main {
  grid-row-end: span 2;
}

.colourSquare {
  height: 100%;
}

.colourDetails {
  margin-top: var(--xsmall);
  font-weight: bold;
  color: hsl(var(--colour-font-lightest-hsl));
}

.colour-background {
  background-color: hsl(var(--colour-background-hsl));
}

.colour-background-light {
  background-color: hsl(var(--colour-background-light-hsl));
}

.colour-background-lighter {
  background-color: hsl(var(--colour-background-lighter-hsl));
}

.colour-background-lightest {
  background-color: hsl(var(--colour-background-lightest-hsl));
}

.colour-background-dark {
  background-color: hsl(var(--colour-background-dark-hsl));
}

.colour-background-darker {
  background-color: hsl(var(--colour-background-darker-hsl));
}

.colour-background-darkest {
  background-color: hsl(var(--colour-background-darkest-hsl));
}

.colour-font {
  background-color: hsl(var(--colour-font-hsl));
}

.colour-font-light {
  background-color: hsl(var(--colour-font-light-hsl));
}

.colour-font-lighter {
  background-color: hsl(var(--colour-font-lighter-hsl));
}

.colour-font-lightest {
  background-color: hsl(var(--colour-font-lightest-hsl));
}

.colour-font-dark {
  background-color: hsl(var(--colour-font-dark-hsl));
}

.colour-font-darker {
  background-color: hsl(var(--colour-font-darker-hsl));
}

.colour-font-darkest {
  background-color: hsl(var(--colour-font-darkest-hsl));
}

.colour-andel-blue1 {
  background-color: hsl(var(--colour-andel-blue1-hsl));
}

.colour-andel-blue1-light {
  background-color: hsl(var(--colour-andel-blue1-light-hsl));
}

.colour-andel-blue1-lighter {
  background-color: hsl(var(--colour-andel-blue1-lighter-hsl));
}

.colour-andel-blue1-lightest {
  background-color: hsl(var(--colour-andel-blue1-lightest-hsl));
}

.colour-andel-blue1-dark {
  background-color: hsl(var(--colour-andel-blue1-dark-hsl));
}

.colour-andel-blue1-darker {
  background-color: hsl(var(--colour-andel-blue1-darker-hsl));
}

.colour-andel-blue1-darkest {
  background-color: hsl(var(--colour-andel-blue1-darkest-hsl));
}

.colour-andel-blue2 {
  background-color: hsl(var(--colour-andel-blue2-hsl));
}

.colour-andel-blue2-light {
  background-color: hsl(var(--colour-andel-blue2-light-hsl));
}

.colour-andel-blue2-lighter {
  background-color: hsl(var(--colour-andel-blue2-lighter-hsl));
}

.colour-andel-blue2-lightest {
  background-color: hsl(var(--colour-andel-blue2-lightest-hsl));
}

.colour-andel-blue2-dark {
  background-color: hsl(var(--colour-andel-blue2-dark-hsl));
}

.colour-andel-blue2-darker {
  background-color: hsl(var(--colour-andel-blue2-darker-hsl));
}

.colour-andel-blue2-darkest {
  background-color: hsl(var(--colour-andel-blue2-darkest-hsl));
}

.colour-andel-blue3 {
  background-color: hsl(var(--colour-andel-blue3-hsl));
}

.colour-andel-blue3-light {
  background-color: hsl(var(--colour-andel-blue3-light-hsl));
}

.colour-andel-blue3-lighter {
  background-color: hsl(var(--colour-andel-blue3-lighter-hsl));
}

.colour-andel-blue3-lightest {
  background-color: hsl(var(--colour-andel-blue3-lightest-hsl));
}

.colour-andel-blue3-dark {
  background-color: hsl(var(--colour-andel-blue3-dark-hsl));
}

.colour-andel-blue3-darker {
  background-color: hsl(var(--colour-andel-blue3-darker-hsl));
}

.colour-andel-blue3-darkest {
  background-color: hsl(var(--colour-andel-blue3-darkest-hsl));
}

.colour-andel-blue4 {
  background-color: hsl(var(--colour-andel-blue4-hsl));
}

.colour-andel-blue4-light {
  background-color: hsl(var(--colour-andel-blue4-light-hsl));
}

.colour-andel-blue4-lighter {
  background-color: hsl(var(--colour-andel-blue4-lighter-hsl));
}

.colour-andel-blue4-lightest {
  background-color: hsl(var(--colour-andel-blue4-lightest-hsl));
}

.colour-andel-blue4-dark {
  background-color: hsl(var(--colour-andel-blue4-dark-hsl));
}

.colour-andel-blue4-darker {
  background-color: hsl(var(--colour-andel-blue4-darker-hsl));
}

.colour-andel-blue4-darkest {
  background-color: hsl(var(--colour-andel-blue4-darkest-hsl));
}

.colour-andel-green {
  background-color: hsl(var(--colour-andel-green-hsl));
}

.colour-andel-green-light {
  background-color: hsl(var(--colour-andel-green-light-hsl));
}

.colour-andel-green-lighter {
  background-color: hsl(var(--colour-andel-green-lighter-hsl));
}

.colour-andel-green-lightest {
  background-color: hsl(var(--colour-andel-green-lightest-hsl));
}

.colour-andel-green-dark {
  background-color: hsl(var(--colour-andel-green-dark-hsl));
}

.colour-andel-green-darker {
  background-color: hsl(var(--colour-andel-green-darker-hsl));
}

.colour-andel-green-darkest {
  background-color: hsl(var(--colour-andel-green-darkest-hsl));
}

.colour-error {
  background-color: hsl(var(--colour-error-hsl));
}

.colour-error-light {
  background-color: hsl(var(--colour-error-light-hsl));
}

.colour-error-lighter {
  background-color: hsl(var(--colour-error-lighter-hsl));
}

.colour-error-lightest {
  background-color: hsl(var(--colour-error-lightest-hsl));
}

.colour-error-dark {
  background-color: hsl(var(--colour-error-dark-hsl));
}

.colour-error-darker {
  background-color: hsl(var(--colour-error-darker-hsl));
}

.colour-error-darkest {
  background-color: hsl(var(--colour-error-darkest-hsl));
}

.colour-success {
  background-color: hsl(var(--colour-success-hsl));
}

.colour-success-light {
  background-color: hsl(var(--colour-success-light-hsl));
}

.colour-success-lighter {
  background-color: hsl(var(--colour-success-lighter-hsl));
}

.colour-success-lightest {
  background-color: hsl(var(--colour-success-lightest-hsl));
}

.colour-success-dark {
  background-color: hsl(var(--colour-success-dark-hsl));
}

.colour-success-darker {
  background-color: hsl(var(--colour-success-darker-hsl));
}

.colour-success-darkest {
  background-color: hsl(var(--colour-success-darkest-hsl));
}

.colour-info {
  background-color: hsl(var(--colour-info-hsl));
}

.colour-info-light {
  background-color: hsl(var(--colour-info-light-hsl));
}

.colour-info-lighter {
  background-color: hsl(var(--colour-info-lighter-hsl));
}

.colour-info-lightest {
  background-color: hsl(var(--colour-info-lightest-hsl));
}

.colour-info-dark {
  background-color: hsl(var(--colour-info-dark-hsl));
}

.colour-info-darker {
  background-color: hsl(var(--colour-info-darker-hsl));
}

.colour-info-darkest {
  background-color: hsl(var(--colour-info-darkest-hsl));
}

.colour-warn {
  background-color: hsl(var(--colour-warn-hsl));
}

.colour-warn-light {
  background-color: hsl(var(--colour-warn-light-hsl));
}

.colour-warn-lighter {
  background-color: hsl(var(--colour-warn-lighter-hsl));
}

.colour-warn-lightest {
  background-color: hsl(var(--colour-warn-lightest-hsl));
}

.colour-warn-dark {
  background-color: hsl(var(--colour-warn-dark-hsl));
}

.colour-warn-darker {
  background-color: hsl(var(--colour-warn-darker-hsl));
}

.colour-warn-darkest {
  background-color: hsl(var(--colour-warn-darkest-hsl));
}

.grid {
  display: grid;
}
.grid.columns-label {
  grid-template-columns: auto 1fr;
}
.grid.columns-label > :nth-child(odd) {
  font-weight: 700;
}
.grid.columns-2 {
  grid-template-columns: [full-start] minmax(0, 1fr) [center] minmax(0, 1fr) [full-end];
}
.grid.columns-3 {
  grid-template-columns: [full-start] minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) [full-end];
}
.grid.columns-7 {
  grid-template-columns: repeat(7, 1fr);
}
.grid.columns-2-Fixed {
  grid-template-columns: 50% 50%;
}
.grid.columns-2-Auto {
  grid-template-columns: auto auto;
}
.grid.columns-2-FreeAuto {
  grid-template-columns: minmax(36ch, 1fr) auto;
}
.grid.columns-2-AutoFree {
  grid-template-columns: auto 1fr;
}
.grid.columns-3-FreeAutoAuto {
  grid-template-columns: 1fr auto auto;
}
.grid.columns-6_3 {
  grid-template-columns: repeat(3, auto 1fr);
}

.flex, .flexVert {
  display: flex;
}
.flexVert {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jContent-spaceBetween {
  justify-content: space-between;
}

.jContent-spaceAround {
  justify-content: space-around;
}

.jContent-start {
  justify-content: start;
}

.jContent-center {
  justify-content: center;
}

.jContent-end {
  justify-content: end;
}

.aContent-start {
  align-content: flex-start;
}

.aContent-center {
  align-content: center;
}

.aContent-end {
  align-content: flex-end;
}

.aItems-start {
  align-items: start;
}

.aItems-end {
  align-items: end;
}

.aItems-center {
  align-items: center;
}

.aItems-stretch {
  align-items: stretch;
}

.jSelf-start {
  justify-self: start;
}

.jSelf-center {
  justify-self: center;
}

.jSelf-end {
  justify-self: end;
}

.aSelf-start {
  align-self: start;
}

.aSelf-center {
  align-self: center;
}

.aSelf-end {
  align-self: end;
}

.aSelf-stretch {
  align-self: stretch;
}

.span-2 {
  grid-column-end: span 2;
}

.span-full {
  grid-column: full;
}

@font-face {
  font-family: "icomoon";
  src: url("../icons/icomoon.eot?48k5uj");
  src: url("../icons/icomoon.eot?48k5uj#iefix") format("embedded-opentype"), url("../icons/icomoon.ttf?48k5uj") format("truetype"), url("../icons/icomoon.woff?48k5uj") format("woff"), url("../icons/icomoon.svg?48k5uj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ranger:before {
  content: "\e900";
}

.icon-archive:before {
  content: "\e901";
}

.icon-flexipad:before {
  content: "\e902";
}

.icon-flowstop:before {
  content: "\e903";
}

.icon-protectedMenu:before {
  content: "\e904";
}

.icon-floor:before {
  content: "\e905";
}

.icon-zoneAlt:before {
  content: "\e906";
}

.icon-target:before {
  content: "\e907";
}

.icon-zone:before {
  content: "\e908";
}

.icon-room:before {
  content: "\e909";
}

.icon-scheme:before {
  content: "\e90a";
}

.icon-erase:before {
  content: "\e90b";
}

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

body {
  margin: 0;
}

.app {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  background-color: hsl(var(--colour-background-hsl));
}
.app.auth {
  grid-template-areas: "appHeader" "pageHeader" "pageHeaderGraphic" "appContent" "appFooter";
  grid-template-rows: auto auto auto 1fr auto;
  justify-items: stretch;
}
.app:is(.guest, .combo) {
  --contentWidth: min(460px, 90%);
  grid-template-areas: "appContent" "appFooter";
  grid-template-rows: 1fr auto;
  align-items: center;
}
.app:is(.guest, .combo) .appMain .appMain-content {
  row-gap: 0;
}

.app-ErrorWrapper {
  --contentWidth: min(460px, 90%);
  grid-template-areas: "appContent" "appFooter";
  grid-template-rows: 1fr auto;
  align-items: center;
}

.appHeader > [class$=-content],
.appMain > [class$=-content],
.appFooter > [class$=-content],
.pageHeader > [class$=-content] {
  position: relative;
  width: var(--contentWidth);
  margin: 0 auto;
  container-type: inline-size;
}

.appHeader {
  --box-shadow: 0px 9px 27px -5px hsla(209, 14%, 30%, 0.35), 0px 5px 16px -8px hsla(209, 14%, 30%, 0.6);
  position: relative;
  grid-area: appHeader;
  height: var(--headerHeight);
  background-color: hsl(var(--colour-andel-blue1-hsl));
}
.appHeader .appHeader-content {
  height: 100%;
}
.appHeader .logo-wrapper {
  display: flex;
  align-items: center;
  height: 50%;
  white-space: nowrap;
}

.appFooter {
  grid-area: appFooter;
  margin-top: var(--medium);
}

.appNav {
  position: relative;
  display: flex;
  align-items: center;
  gap: calc(var(--small) + var(--xsmall));
}
.appNav :is(a, .navItem) {
  display: flex;
  align-items: center;
  gap: var(--xsmall);
  height: auto;
  color: hsl(var(--colour-background-hsl));
  font-weight: 400;
}
.appNav :is(a, .navItem):is(:hover, :active) {
  transform: scale(1) !important;
}

.appMain {
  grid-area: appContent;
}
.appMain .appMain-content {
  display: flex;
  flex-direction: column;
  row-gap: var(--large);
}
.appMain .appMain-content:not(:has(.actions)) {
  margin-top: var(--small);
}
.appMain .appMain-content section > header,
.appMain .appMain-content section > .flex > header {
  position: relative;
}
.appMain .appMain-content section > header:not([class^=marginBottom]),
.appMain .appMain-content section > .flex > header:not([class^=marginBottom]) {
  margin-bottom: var(--large);
}
.appMain .appMain-content section > header::after,
.appMain .appMain-content section > .flex > header::after {
  --height: 6px;
  content: "";
  position: absolute;
  bottom: calc(var(--height) * -2);
  height: var(--height);
  width: 80px;
  background-color: hsl(var(--colour-andel-green-hsl));
  border-radius: calc(var(--height) / 2);
}

.appName {
  container-type: inline-size;
}
.appName span {
  margin-right: -0.65rem;
  letter-spacing: 0.65rem;
}

.hidden {
  display: none;
}

:is(header, h3).withDivider {
  position: relative;
  padding-bottom: var(--xsmall);
}
:is(header, h3).withDivider:not([class^=marginBottom]) {
  margin-bottom: var(--medium);
}
:is(header, h3).withDivider:not(.no-divider)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 60px;
  background-color: hsl(var(--colour-andel-blue3-hsl));
  border-radius: 2px;
}

.divider {
  height: auto;
  width: 50px;
}
.divider .wave path {
  fill: url(#gradient);
}
.divider #gradient {
  --colour-start: hsl(var(--colour-andel-blue3-hsl));
  --colour-stop: hsl(var(--colour-andel-blue4-hsl));
}

.highlightItem {
  align-items: center;
  width: max-content;
}

.block {
  display: block;
}

.listSeparator + .listSeparator {
  border-top: 1px solid hsl(var(--colour-font-lighter-hsl));
}

.margin-xsmall {
  margin: var(--xsmall);
}

.marginBlock-xsmall {
  margin-block: var(--xsmall);
}

.marginInline-xsmall {
  margin-inline: var(--xsmall);
}

.marginTop-xsmall {
  margin-top: var(--xsmall);
}

.marginBottom-xsmall {
  margin-bottom: var(--xsmall);
}

.marginLeft-xsmall {
  margin-left: var(--xsmall);
}

.marginRight-xsmall {
  margin-right: var(--xsmall);
}

.margin-small {
  margin: var(--small);
}

.marginBlock-small {
  margin-block: var(--small);
}

.marginInline-small {
  margin-inline: var(--small);
}

.marginTop-small {
  margin-top: var(--small);
}

.marginBottom-small {
  margin-bottom: var(--small);
}

.marginLeft-small {
  margin-left: var(--small);
}

.marginRight-small {
  margin-right: var(--small);
}

.margin-medium {
  margin: var(--medium);
}

.marginBlock-medium {
  margin-block: var(--medium);
}

.marginInline-medium {
  margin-inline: var(--medium);
}

.marginTop-medium {
  margin-top: var(--medium);
}

.marginBottom-medium {
  margin-bottom: var(--medium);
}

.marginLeft-medium {
  margin-left: var(--medium);
}

.marginRight-medium {
  margin-right: var(--medium);
}

.margin-large {
  margin: var(--large);
}

.marginBlock-large {
  margin-block: var(--large);
}

.marginInline-large {
  margin-inline: var(--large);
}

.marginTop-large {
  margin-top: var(--large);
}

.marginBottom-large {
  margin-bottom: var(--large);
}

.marginLeft-large {
  margin-left: var(--large);
}

.marginRight-large {
  margin-right: var(--large);
}

.margin-xlarge {
  margin: var(--xlarge);
}

.marginBlock-xlarge {
  margin-block: var(--xlarge);
}

.marginInline-xlarge {
  margin-inline: var(--xlarge);
}

.marginTop-xlarge {
  margin-top: var(--xlarge);
}

.marginBottom-xlarge {
  margin-bottom: var(--xlarge);
}

.marginLeft-xlarge {
  margin-left: var(--xlarge);
}

.marginRight-xlarge {
  margin-right: var(--xlarge);
}

.margin-none {
  margin: 0;
}

.marginBlock-none {
  margin-block: 0;
}

.marginInline-none {
  margin-inline: 0;
}

.marginTop-none {
  margin-top: 0;
}

.marginBottom-none {
  margin-bottom: 0;
}

.marginLeft-none {
  margin-left: 0;
}

.marginRight-none {
  margin-right: 0;
}

.padding-xsmall {
  padding: var(--xsmall);
}

.paddingBlock-xsmall {
  padding-block: var(--xsmall);
}

.paddingInline-xsmall {
  padding-inline: var(--xsmall);
}

.paddingTop-xsmall {
  padding-top: var(--xsmall);
}

.paddingBottom-xsmall {
  padding-bottom: var(--xsmall);
}

.paddingLeft-xsmall {
  padding-left: var(--xsmall);
}

.paddingRight-xsmall {
  padding-right: var(--xsmall);
}

.padding-small {
  padding: var(--small);
}

.paddingBlock-small {
  padding-block: var(--small);
}

.paddingInline-small {
  padding-inline: var(--small);
}

.paddingTop-small {
  padding-top: var(--small);
}

.paddingBottom-small {
  padding-bottom: var(--small);
}

.paddingLeft-small {
  padding-left: var(--small);
}

.paddingRight-small {
  padding-right: var(--small);
}

.padding-medium {
  padding: var(--medium);
}

.paddingBlock-medium {
  padding-block: var(--medium);
}

.paddingInline-medium {
  padding-inline: var(--medium);
}

.paddingTop-medium {
  padding-top: var(--medium);
}

.paddingBottom-medium {
  padding-bottom: var(--medium);
}

.paddingLeft-medium {
  padding-left: var(--medium);
}

.paddingRight-medium {
  padding-right: var(--medium);
}

.padding-large {
  padding: var(--large);
}

.paddingBlock-large {
  padding-block: var(--large);
}

.paddingInline-large {
  padding-inline: var(--large);
}

.paddingTop-large {
  padding-top: var(--large);
}

.paddingBottom-large {
  padding-bottom: var(--large);
}

.paddingLeft-large {
  padding-left: var(--large);
}

.paddingRight-large {
  padding-right: var(--large);
}

.padding-xlarge {
  padding: var(--xlarge);
}

.paddingBlock-xlarge {
  padding-block: var(--xlarge);
}

.paddingInline-xlarge {
  padding-inline: var(--xlarge);
}

.paddingTop-xlarge {
  padding-top: var(--xlarge);
}

.paddingBottom-xlarge {
  padding-bottom: var(--xlarge);
}

.paddingLeft-xlarge {
  padding-left: var(--xlarge);
}

.paddingRight-xlarge {
  padding-right: var(--xlarge);
}

.padding-none {
  padding: 0;
}

.paddingBlock-none {
  padding-block: 0;
}

.paddingInline-none {
  padding-inline: 0;
}

.paddingTop-none {
  padding-top: 0;
}

.paddingBottom-none {
  padding-bottom: 0;
}

.paddingLeft-none {
  padding-left: 0;
}

.paddingRight-none {
  padding-right: 0;
}

.gap-xsmall {
  gap: var(--xsmall);
}

.gapRow-xsmall {
  row-gap: var(--xsmall);
}

.gapCol-xsmall {
  column-gap: var(--xsmall);
}

.gap-small {
  gap: var(--small);
}

.gapRow-small {
  row-gap: var(--small);
}

.gapCol-small {
  column-gap: var(--small);
}

.gap-medium {
  gap: var(--medium);
}

.gapRow-medium {
  row-gap: var(--medium);
}

.gapCol-medium {
  column-gap: var(--medium);
}

.gap-large {
  gap: var(--large);
}

.gapRow-large {
  row-gap: var(--large);
}

.gapCol-large {
  column-gap: var(--large);
}

.gap-xlarge {
  gap: var(--xlarge);
}

.gapRow-xlarge {
  row-gap: var(--xlarge);
}

.gapCol-xlarge {
  column-gap: var(--xlarge);
}

.gap-none {
  gap: 0;
}

.gapRow-none {
  row-gap: 0;
}

.gapCol-none {
  column-gap: 0;
}

body,
.appWrapper {
  font-family: var(--fontFamily);
  color: hsl(var(--colour-font-hsl));
}

h1,
h2,
h3,
h4,
h5,
p,
pre {
  margin: 0;
  line-height: 1.1;
}

h1 {
  font-size: 4.75rem;
  line-height: 0.9;
  letter-spacing: -2px;
}

h2 {
  font-size: clamp(2rem, 4.5cqw, 4rem);
  letter-spacing: -1px;
}

h3 {
  letter-spacing: -1px;
}

p {
  line-height: 1.4;
}
p + p {
  margin-top: var(--small);
}

a,
.link,
.p-link {
  cursor: pointer;
  color: hsl(var(--colour-andel-blue1-hsl));
  text-decoration: none;
  transition: color 350ms ease;
  font-family: var(--fontFamily);
  font-size: var(--fontSize-standard);
}
a:not(.p-disabled, :disabled, .button):hover,
.link:not(.p-disabled, :disabled, .button):hover,
.p-link:not(.p-disabled, :disabled, .button):hover {
  color: hsl(var(--colour-andel-green-hsl));
}
a:is(.p-disabled, :disabled, [disabled]),
.link:is(.p-disabled, :disabled, [disabled]),
.p-link:is(.p-disabled, :disabled, [disabled]) {
  color: hsl(var(--colour-andel-blue1-lighter-hsl));
}
a[disabled],
.link[disabled],
.p-link[disabled] {
  pointer-events: none;
}

::placeholder {
  color: hsl(var(--colour-font-lighter-hsl));
}

.fontSize-small {
  font-size: var(--fontSize-small);
}
.fontSize-medium {
  font-size: var(--fontSize-medium);
}
.fontSize-large {
  font-size: var(--fontSize-large);
}
.fontSize-xlarge {
  font-size: var(--fontSize-xlarge);
}
.fontWeight-regular {
  font-weight: 400;
}
.fontWeight-bold {
  font-weight: 700;
}
.fontColour-light {
  color: hsl(var(--colour-font-light-hsl));
}
.fontColour-lighter {
  color: hsl(var(--colour-font-lighter-hsl));
}
.fontColour-lightest {
  color: hsl(var(--colour-font-lightest-hsl));
}
.fontColour-dark {
  color: hsl(var(--colour-font-dark-hsl));
}
.fontColour-darker {
  color: hsl(var(--colour-font-darker-hsl));
}
.fontColour-darkest {
  color: hsl(var(--colour-font-darkest-hsl));
}
.fontColour-error {
  color: hsl(var(--colour-error-hsl));
}
.fontColour-success {
  color: hsl(var(--colour-success-hsl));
}
.fontColour-info {
  color: hsl(var(--colour-info-hsl));
}
.fontColour-warn {
  color: hsl(var(--colour-warn-hsl));
}
.fontColour-background {
  color: hsl(var(--colour-background-hsl));
}
.fontColour-andel-blue1 {
  color: hsl(var(--colour-andel-blue1-hsl));
}
.fontColour-andel-blue2 {
  color: hsl(var(--colour-andel-blue2-hsl));
}
.fontColour-andel-blue3 {
  color: hsl(var(--colour-andel-blue3-hsl));
}
.fontColour-andel-blue4 {
  color: hsl(var(--colour-andel-blue4-hsl));
}
.fontColour-andel-green {
  color: hsl(var(--colour-andel-green-hsl));
}

.text-lineHeight-1 {
  line-height: 1;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-copy {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: var(--medium);
  padding: var(--xsmall);
  background-color: hsl(var(--colour-andel-green-lightest-hsl));
  border: 1px solid hsl(var(--colour-andel-green-lighter-hsl));
}
.text-copy span {
  width: max-content;
}
.text-vertical, .text-verticalAlt {
  line-height: 1;
  writing-mode: vertical-lr;
}
.text-verticalAlt {
  rotate: 180deg;
}

.label {
  font-weight: 700;
}

.highlight-onChange:not(.fieldBoolean) {
  color: hsl(var(--colour-andel-blue3-hsl));
  transition: color 250ms ease !important;
}

[data-theme=dark] .appHeader,
[data-theme=dark] .pageHeader,
[data-theme=dark] .pageHeader-graphic {
  --colour-andel-blue1-hsl: 216deg, 40%, 5%;
}
[data-theme=dark] .appHeader #logo #andel-text,
[data-theme=dark] .appHeader #logo #left-1,
[data-theme=dark] .appHeader #logo #left-2,
[data-theme=dark] .appHeader #logo #left-3,
[data-theme=dark] .appHeader #logo #right-1,
[data-theme=dark] .appHeader #logo #right-2,
[data-theme=dark] .appHeader #logo #right-3 {
  fill: hsl(var(--colour-andel-blue1-light-hsl));
}
[data-theme=dark] .pageHeader h1 {
  color: hsl(var(--colour-andel-blue1-light-hsl));
}
[data-theme=dark] .pageHeader h2,
[data-theme=dark] .pageHeader .goBack {
  color: hsl(var(--colour-andel-blue1-dark-hsl));
}
[data-theme=dark] .pageHeader-graphic svg .curve-blue2 {
  fill: hsl(var(--colour-andel-blue2-darkest-hsl));
}
[data-theme=dark] .appNav :is(a, .navItem) {
  color: hsl(var(--colour-andel-blue1-lighter-hsl));
}
